import styled from "@emotion/styled";

const tableBorderStyle = "border: 1px solid black;"
export const StyledTable = styled.table`
  ${tableBorderStyle}
`;

export const StyledRow = styled.tr`
${tableBorderStyle}
`;

export const StyledHeaderCell = styled.th`
    ${tableBorderStyle}
`;

export const StyledContentRow = styled.tr<{ index: number }>`
  background-color: ${props => (props.index % 2 === 0) ? "#00963622" : "#ffffff"};
  cursor: pointer;
`;