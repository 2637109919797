import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DatePickerContainerProps } from "../models/Props";
import { StyledDatePickerContainer } from "../styles/styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export function DatePickerContainer({
    startDate,
    endDate,
    setStartDate,
    setEndDate
}: DatePickerContainerProps) {
    return (
        <StyledDatePickerContainer>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                label="Startdatum"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
            />
            <DateTimePicker
                label="Slutdatum"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
            />
        </LocalizationProvider>
    </StyledDatePickerContainer>
    );
}