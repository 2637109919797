import { useCallback, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import IPostOptions from "../interfaces/IPostOptions";
import IPutOptions from "../interfaces/IPutOptions";
import IDeleteOptions from "../interfaces/IDeleteOptions";

import { configuration } from "../config";

type IOptions = IPostOptions | IPutOptions | IDeleteOptions;

function useFetch() {
  const { getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  const runner = useCallback(
    async (url: string, options?: IOptions, abortSignal?: AbortSignal) => {
      setIsLoading(true);
      let result: any;

      try {
        const accessToken = await getAccessTokenSilently().catch((error) => {
          if (error.error !== "login_required") {
            return error.error;
          }
        });

        if (!accessToken) {
          /**
           * If no access token we check if we should login the user automatically
           * If window.location.origin is an URL specified for automatic login -
           * the user will be logged in without clicking "Login"-btn. Otherwise the
           * login page is presented.
           *
           * Reason for approach: Automatically login caused major delay when feedsmart
           * runs stand alone. When running feedsmart integrated to LM2 automatic login
           * works fine.
           */
          const { origin } = window.location;
          if (configuration.autoLoginAtUrls.includes(origin)) {
            await loginWithRedirect();
          } else {
            logout({ returnTo: window.location.origin })
          }
          return;
        }

        const headers = {
          ...(options?.headers instanceof Array ? options.headers : []),
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        };

        const requestOptions = {
          headers,
          abortSignal,
          ...options,
        };

        result = await fetch(url, requestOptions);

        if (!result.ok) {
          /**
           * If we receive an error from api response we will most likely
           * want to prompt the user with issue with request. We therefore send
           * the status code back and handle the issue where the request is sent.
           */
          if (result.status === 400 || result.status === 500 || result.status === 409) {
            return result.status;
          }
          throw new Error(result.status);
        }

        if (!options && result.ok) {
          /*
           * It's only the get method that doesn't have any options
           * object, so if the options object is absent it can only
           * be a get request, therefor we'll want to parse the data
           * and return that instead.
           */

          // Next Line for debugging purposes, since you can't do json() on undefined,
          // this will simulate a crash or if the database is down.
          // result = undefined

          result = await result.json();
        }

        setIsLoading(false);

        /*
         *If an option object is present it must be a POST, PUT
         * or DELETE and no data is return really, or we are
         * atleast not interested in it, that's why we just
         * return the result of the resolved promise.
         */
        return result;
      } catch (error: any) {
        console.log(error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getAccessTokenSilently, loginWithRedirect]
  );

  const get = useCallback(async (url: string, abortSignal?: AbortSignal) => runner(url), [runner]);

  const post = async (url: string, options: IPostOptions) => runner(url, options);

  const put = async (url: string, options: IPutOptions) => runner(url, options);

  // Named deleteRequest since naming something "delete" is not allowed
  const deleteRequest = async (url: string, options: IDeleteOptions) => runner(url, options);
  return { get, post, put, deleteRequest, isLoading };
}

export default useFetch;
