import { FC } from 'react'
import { CardActions, CardBody, CardHeader } from '../../shared/Card';
import { LmButtonGreen } from '../../lm2/LmButton';
import { LmModal } from '../../lm2/LmModal';

export interface IProps {
  onClose: (() => void) | undefined
  data: string
}

export const DataModal: FC<IProps> = ({ data, onClose }) => {
  return (
    <LmModal onClose={() => onClose}>
      <CardHeader>
        <h1>Event data</h1>
      </CardHeader>
      <CardBody>
        {data}
      </CardBody>
      <CardActions justifyContent='center'>
        <LmButtonGreen onClick={onClose}>Stäng</LmButtonGreen>
      </CardActions>
    </LmModal>
  );
}