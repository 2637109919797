interface Props {
  children: any;
}

export const LmList = ({ children }: Props) => {
  return (
    <section className="lm__news link-listing with-animation">
      {children}
    </section>
  );
};
