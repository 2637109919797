import styled from '@emotion/styled';
import React from 'react';
import { FlexContainer, FlexItem } from './FlexLayout';

const StyledInput = styled.input`
  width: 100%;
`;

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
}

export const InputField = ({ label, ...props }: Props) => {
  return (
    <FlexContainer justify="space-between">
      <FlexItem>
        <label htmlFor="signature">{label}</label>
      </FlexItem>
      <FlexItem width={60}>
        <StyledInput {...props} />
      </FlexItem>
    </FlexContainer>
  );
};
