import styled from '@emotion/styled';

interface Props {
  justify?: string;
}

export const FlexContainer = styled.div<Props>`
  display: flex;
  justify-content: ${({ justify }) => justify || 'flex-start'};
`;

export const FlexItem = styled.div<{ width?: number }>`
  flex: 0 0 ${({ width }) => (width ? `${width}%` : 'auto')};
`;
