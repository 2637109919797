import { LmModal } from '../lm2/LmModal';
import { CardActions, CardBody, CardHeader } from "../shared/Card";
import { InputField } from "../shared/InputField";
import Select from 'react-select';
import { LmButtonGreen, LmButtonYellow } from "../lm2/LmButton";
import { useEffect, useMemo, useState } from "react";
import { TextareaField } from "../shared/TextareaField";
import dayjs, { Dayjs } from "dayjs";
import useFetch from "../../hooks/useFetch";
import { StyledSelect } from "./styles/styles";
import { Props } from "./models/Props";
import { configuration } from "../../config";
import { PublishMessage, Location, MessageRule } from "../../types";
import { DatePickerContainer } from "./components/DatePickerContainer";
import { MessageType } from '../../constants/messageTypes';

const { backendBaseUrl } = configuration;

export const MessageModal = ({
    onClose }: Props) => {
    const [location, setLocation] = useState<string>('');
    const [labCode, setLabCode] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [chosenMessageType, setChosenMessageType] = useState<string>('');
    const messageTypes = [
        { id: MessageType.SAMPLE_MESSAGE, name: 'Provmeddelande' },
        { id: MessageType.LOCATION_MESSAGE, name: 'Platsmeddelande' },
        { id: MessageType.GLOBAL_MESSAGE, name: 'Globalt meddelande' }
    ]
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
    const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
    const [locations, setLocations] = useState([]);
    const { put, get } = useFetch();
    const sortedLocations = useMemo(
        () => [...locations].sort((a: Location, b: Location) => a.name > b.name ? 1 : -1),
        [locations]
    );

    useEffect(() => {
        setLabCode('')
        setLocation('')
    }, [chosenMessageType]);

    useEffect(() => {
        (async () => {
            const response = await get(`${backendBaseUrl}/api/GetLocations?onlyActive=true`);
            setLocations(await response);
        })();
    }, [get]);

    const handleSubmit = async () => {
        if (startDate === null || endDate === null) {
            return;
        }
        const data: PublishMessage[] = [{
            type: chosenMessageType,
            text: message,
            startDate: startDate?.unix(),
            endDate: endDate?.unix(),
            rules: []
        }];
        if (chosenMessageType === MessageType.SAMPLE_MESSAGE) {
            const rule: MessageRule = {
                rule: "$.lab",
                value: labCode,
                operator: "eq"
            }
            data[0].rules = [rule]
        }
        if (chosenMessageType === MessageType.LOCATION_MESSAGE) {
            data[0].location = location
        }
        await put(
            `${backendBaseUrl}/api/messages`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
            }
        );
        onClose()
    }

    const isSaveDisabled = (): boolean => {
        let disabled = true
        //Enable rules
        if (chosenMessageType !== '' && message.length > 0) {
            disabled = false
        }

        //Disable rules
        if (chosenMessageType === MessageType.LOCATION_MESSAGE && location.length < 1) {
            disabled = true
        }
        if (chosenMessageType === MessageType.SAMPLE_MESSAGE && labCode.length < 1) {
            disabled = true
        }
        return disabled
    }

    return (
        <LmModal>
            <CardHeader>
                <h1>Lägg till meddelande</h1>
                <StyledSelect>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="messageType"
                        placeholder="Välj meddelandetyp..."
                        options={messageTypes}
                        getOptionLabel={(item: any) => item.name}
                        getOptionValue={(item: any) => item.name}
                        onChange={(item: any) => setChosenMessageType(item.id)}
                    />
                </StyledSelect>
                <DatePickerContainer
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}/>
            </CardHeader>
            <CardBody>
                {chosenMessageType === MessageType.SAMPLE_MESSAGE &&
                    <InputField
                        label="Labbkod"
                        type="text"
                        id="labcode"
                        name="labcode"
                        placeholder="Ange labbkod"
                        value={labCode || ''}
                        onChange={(event) => {
                            setLabCode(event.target.value)
                        }}
                        required
                    />}
                {chosenMessageType === MessageType.LOCATION_MESSAGE &&
                    <StyledSelect>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="locations"
                            placeholder="Välj plats..."
                            options={sortedLocations}
                            getOptionLabel={(item: any) => item.name}
                            getOptionValue={(item: any) => item.name}
                            onChange={(item: any) => setLocation(item.name)}
                        />
                    </StyledSelect>}
                <TextareaField
                    label="Meddelande"
                    id="message"
                    name="message"
                    placeholder="Ange meddelande"
                    value={message || ''}
                    onChange={(event) => {
                        setMessage(event.target.value)
                    }}
                    required
                />
            </CardBody>
            <CardActions>
                <LmButtonYellow onClick={onClose}>Avbryt</LmButtonYellow>
                <LmButtonGreen onClick={handleSubmit} disabled={isSaveDisabled()}>
                    Lägg till
                </LmButtonGreen>
            </CardActions>
        </LmModal>
    );
};
