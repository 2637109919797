import styled from '@emotion/styled';

import { LmButton } from './lm2/LmButton';
import { useEffect, useState } from 'react';
import { Device, DeviceInput } from '../types';
import { FlexContainer, FlexItem } from './FlexLayout';
import { LmInput } from './lm2/LmInput';
import { LinearLoader } from './shared/LinearLoader';
import { Overlay } from './shared/Overlay';
import { CardColumn } from './shared/Card';

interface Props {
  device?: Device;
  onSave: (inputValue: DeviceInput) => Promise<void>;
}

const Card = styled.div`
  position: relative;
  border: 2px solid silver;
  padding: 20px;
  margin-bottom: 4px;
  @media (max-width: 650px) {
    padding: 10px;
  }
`;

const CheckboxLabel = styled.label`
  width: 30%
`;

export const DeviceCard = ({ device, onSave }: Props) => {

  const createDeviceInput = (device?: Device): DeviceInput => {
    return {
      deviceId: device?.id ?? '',
      deviceType: device?.deviceType ?? '',
      deviceModel: device?.deviceModel ?? '',
      location: device?.location ?? '',
      alias: device?.alias ?? '',
      serialNumber: device?.serialNumber ?? '',
      eurofinsCode: device?.eurofinsCode ?? '',
      sendToM3: device?.sendToM3 ?? false,
      createTraceability: device?.createTraceability ?? false,
    }
  }
  const [inputValue, setInputValue] = useState<DeviceInput>(createDeviceInput(device));
  const [loading, setLoading] = useState(false);

  const handleChange = (event: any) => {
    setInputValue({ ...inputValue, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    setInputValue(createDeviceInput(device));
  }, [device]);

  const handleSubmit = async (inputValue: DeviceInput) => {
    setLoading(true);
    await onSave(inputValue);
    setLoading(false);
  };

  return (
    <Card>
      {loading ? (
        <Overlay>
          <LinearLoader />
        </Overlay>
      ) : null}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(inputValue);
        }}
      >
        <h3 style={{ textAlign: 'center' }}>Editera instrument</h3>
        <FlexContainer>
          <CardColumn width={50}>
            <FlexItem width={40}>
              <label htmlFor="id">Instrumentid</label>
              <LmInput
                type="text"
                id="id"
                name="deviceId"
                value={inputValue.deviceId}
                onChange={handleChange}
              />
              <label htmlFor="deviceType">Instrumenttyp</label>
              <LmInput
                type="text"
                id="deviceType"
                name="deviceType"
                value={inputValue.deviceType}
                onChange={handleChange}
              />
              <label htmlFor="deviceModel">Instrumentmodell</label>
              <LmInput
                type="text"
                id="deviceModel"
                name="deviceModel"
                value={inputValue.deviceModel}
                onChange={handleChange}
              />
              <label htmlFor="ort">Ort</label>
              <LmInput
                type="text"
                id="ort"
                name="location"
                value={inputValue.location}
                onChange={handleChange}
              />
            </FlexItem>
          </CardColumn>
          <CardColumn width={50}>
            <FlexItem width={50}>
              <label htmlFor="alias">Alias</label>
              <LmInput
                type="text"
                id="alias"
                name="alias"
                value={inputValue.alias}
                onChange={handleChange}
              />
              <label htmlFor="serialNumber">Serienummer</label>
              <LmInput
                type="text"
                id="serialNumber"
                name="serialNumber"
                value={inputValue.serialNumber}
                onChange={handleChange}
              />
              <label htmlFor="eurofinsCode">Inventarienummer</label>
              <LmInput
                type="text"
                id="eurofinsCode"
                name="eurofinsCode"
                value={inputValue.eurofinsCode}
                onChange={handleChange}
              />
              <div>
                <CheckboxLabel htmlFor="sendToM3">Skicka till M3</CheckboxLabel>
                <LmInput
                  type="checkbox"
                  id="sendToM3"
                  name="sendToM3"
                  checked={inputValue.sendToM3}
                  onChange={(e) =>
                    setInputValue({
                      ...inputValue,
                      [e.target.name]: e.target.checked,
                    })
                  }
                />
              </div>
              <div>
                <CheckboxLabel htmlFor="createTraceability">Skapa spårbarhet</CheckboxLabel>
                <LmInput
                  type="checkbox"
                  id="createTraceability"
                  name="createTraceability"
                  checked={inputValue.createTraceability}
                  onChange={(e) =>
                    setInputValue({
                      ...inputValue,
                      [e.target.name]: e.target.checked,
                    })
                  }
                />
              </div>
            </FlexItem>
          </CardColumn>
        </FlexContainer>
        <FlexContainer justify="flex-end">
          <FlexItem>
            <LmButton>Spara</LmButton>
          </FlexItem>
        </FlexContainer>
      </form>
    </Card>
  );
};
