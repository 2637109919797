import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { MessageListProps } from "./models/Props";
import { MessageListItem } from "./components/MessageListItem";


export const MessageList = ({
   messages, deleteMessage }: MessageListProps) => {
  return (
    <TableContainer>
      <Table size="small" aria-label="Events table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Meddelande</TableCell>
            <TableCell align="left">Typ</TableCell>
            <TableCell align="left">Plats</TableCell>
            <TableCell align="left">Labbkod</TableCell>
            <TableCell align="left">Startdatum</TableCell>
            <TableCell align="left">Slutdatum</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {messages?.map((message, index) => {
            return <MessageListItem message={message} deleteMessage={deleteMessage} key={index}/>;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};