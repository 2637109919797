import { FC } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { EditDevice } from './components/EditDevice';
import { AddDevice } from './components/AddDevice';
import { Location } from './components/Location';
import { MainPage } from './components/MainPage';
import { Messages } from './components/Messages';

const App: FC = () => {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/location/:location/add" component={AddDevice} />
          <Route exact path="/location/:location/:deviceId" component={EditDevice} />
          <Route exact path="/location/:location" component={Location} />
          <Route exact path="/messages" component={Messages} />
          <Route path="/" component={MainPage} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
