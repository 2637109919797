import styled from '@emotion/styled';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: any;
}

export const LmButton = ({ children, ...props }: Props) => {
  return (
    <button {...props} className="lm__form-btn">
      {children}
    </button>
  );
};

export const LmButtonBlue = styled(LmButton)`
  background-color: #009fe3;
  color: #fff;
  border-color: #009fe3;
  &:focus {
    outline: 2px solid orange;
  }
`;

export const LmButtonGreen = styled(LmButton)`
  background-color: #009636;
  color: #fff;
  border-color: #009636;
  &:focus {
    outline: 2px solid orange;
  }
`;

export const LmButtonYellow = styled(LmButton)`
  background-color: #ea8d00;
  color: #fff;
  border-color: #ea8d00;
  &:focus {
    outline: 2px solid orange;
  }
`;
