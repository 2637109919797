export const LmInput = (
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
) => {
  return (
    <input className="layout__item  u-1/2-mobile " type="text" {...props} />
  );
};
