import styled from "@emotion/styled";

export const StyledSelect = styled.div`
  input {
    border: 0 !important;
    padding: 0 !important;
  }
`;

export const StyledDatePickerContainer = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 0;

  input {
     border: 0 !important;
  }
`;