import React, { FC, useCallback, useEffect, useState } from "react";
import { CardColumn } from "../shared/Card";
import { LinearLoader } from "../shared/LinearLoader";
import { Overlay } from "../shared/Overlay";
import { FlexContainer, FlexItem } from "../shared/FlexLayout";
import { Card } from "./components/Card";
import { LmButton } from "../lm2/LmButton";
import { DeviceEventsTableProps } from "./models/props";
import useFetch from '../../hooks/useFetch';
import { configuration } from "../../config";
import { LmInput } from "../lm2/LmInput";
import { useParams } from 'react-router';
import { DataModal } from "./components/DataModal";
import { StyledContentRow, StyledHeaderCell, StyledRow, StyledTable } from "./components/StyledTableComponents";

const { backendBaseUrl } = configuration;

export const DeviceEventsTable: FC<DeviceEventsTableProps> = ({ device }) => {
    const { location } = useParams<{ location: string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [dataModalContent, setDataModalContent] = useState<string | undefined>(undefined);
    const [doFetch, setDoFetch] = useState<boolean>(true);
    const [events, setEvents] = useState<{ deviceId: string, data: any, _ts: number }[]>([]);
    const [filter, setFilter] = useState("");
    const { get } = useFetch();

    const applyFilter = (_event: React.ChangeEvent<HTMLInputElement>, newFilter: string) => {
        setFilter(newFilter === filter ? "" : newFilter);
        setDoFetch(true);
    }

    const appendFilter = useCallback((): string => {
        switch (filter) {
            case "raw":
                return '&filter=raw';
            case "status":
                return '&filter=status';
            default:
                return '';
        }
    }, [filter]);

    const getEvents = useCallback(async () => {
        if (!loading && device && location) {
            setLoading(true);
            let url: string = encodeURI(`${backendBaseUrl}/api/GetEvents?location=${location}&deviceId=${device.id}${appendFilter()}`);
            let resp = await get(url);
            console.log(resp)
            setEvents(resp);
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, device, location, appendFilter]);

    const refreshEvents = (event: any) => {
        event.preventDefault();
        if (!loading) {
            setDoFetch(true);
        }
    };

    useEffect(() => {
        if (doFetch) {
            setDoFetch(false);
            getEvents();
        }
    }, [getEvents, doFetch, setDoFetch]);

    const getDataContent = (data: any) => {
        const MAX_LENGTH = 80;
        const dataString = data ? JSON.stringify(data) : "";

        return dataString.length > MAX_LENGTH
            ? `${dataString.substring(0, MAX_LENGTH)}...`
            : dataString;
    }

    const formatDate = (unixTimeStamp: number): string => {
        return  new Date(unixTimeStamp * 1000).toLocaleString();
    }

    return (
        <Card>
            {loading ? (
                <Overlay>
                    <LinearLoader />
                </Overlay>
            ) : null}

            <FlexContainer style={{
                marginBottom: "10px"
            }}>
                <CardColumn width={50} >
                    <FlexContainer justify="flex-start" >
                        <h3 style={{ textAlign: 'center' }}>Instrumentets senaste transaktioner</h3>
                    </FlexContainer>
                    <FlexContainer justify="flex-start" >
                        <h4 style={{ textAlign: 'center' }}>Event types</h4>
                    </FlexContainer>
                    <FlexContainer justify="flex-start" >
                        <label htmlFor="statusfilter">Status</label>
                        <LmInput
                            type="checkbox"
                            id="statusfilter"
                            name="statusfilter"
                            checked={filter === "status"}
                            onChange={(event) => applyFilter(event, 'status')} />
                        <label htmlFor="datafilter">Data</label>
                        <LmInput
                            type="checkbox"
                            id="datafilter"
                            name="datafilter"
                            checked={filter === "raw"}
                            onChange={(event) => applyFilter(event, 'raw')} />
                    </FlexContainer>
                </CardColumn>
                <CardColumn width={50}>
                    <FlexContainer justify="flex-end">
                        <FlexItem>
                            <LmButton
                                disabled={loading}
                                onClick={refreshEvents}>
                                Ladda om
                            </LmButton>
                        </FlexItem>
                    </FlexContainer>
                </CardColumn>
            </FlexContainer>
            <FlexContainer>
                <StyledTable>
                    <thead>
                        <StyledRow>
                            <StyledHeaderCell>Tid</StyledHeaderCell>
                            <StyledHeaderCell>Data</StyledHeaderCell>
                        </StyledRow>
                    </thead>
                    <tbody>
                        {events?.map((event, i) => {
                            return (
                                <StyledContentRow
                                    index={i}
                                    key={event._ts}
                                    onClick={() => setDataModalContent(JSON.stringify(event["data"]))}
                                >
                                    <td>{formatDate(event._ts)}</td>
                                    <td >{getDataContent(event.data)}</td>
                                </StyledContentRow>
                            )
                        })
                        }
                    </tbody>
                </StyledTable>
            </FlexContainer>
            {dataModalContent && (
                <DataModal
                    onClose={() => setDataModalContent(undefined)}
                    data={dataModalContent}
                />)
            }
        </Card>
    );
}