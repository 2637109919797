import styled from "@emotion/styled";

interface Props {
  children: any;
}

const BlockHeader = styled.div`
  background-color: #009636;
`;

export const LmBlockHeader = ({ children }: Props) => {
  return (
    <BlockHeader className="lm__block-header" style={{backgroundColor: '#009636'}}>
      <h1>{children}</h1>
    </BlockHeader>
  );
};
