import { useParams } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import { Device, DeviceInput } from '../types';
import { LmBlock } from './lm2/LmBlock';
import { Breadcrumbs } from './BreadCrumbs';
import { Breadcrumb } from './BreadCrumb';
import { PageHead } from './PageHead';
import { DeviceCard } from './DeviceCard';
import { configuration } from "../config";
import useFetch from '../hooks/useFetch';
import { DeviceEventsTable } from './DeviceEventsTable/DeviceEventsTable';

const { backendBaseUrl } = configuration;

type DeviceType = {
  deviceId: string;
  location: string;
};

export const EditDevice = () => {
  const [device, setDevice] = useState<Device | undefined>();
  const { deviceId, location } = useParams<DeviceType>();
  const { get, put } = useFetch();

  const getDevice = useCallback(async () => {
    const response = await get(`${backendBaseUrl}/api/devices/${deviceId}`);
    setDevice(await response);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDevice, deviceId]);

  useEffect(() => {
    getDevice();
  }, [getDevice]);

  const handleDevice = async (inputValue: DeviceInput) => {
    const data = {
      deviceType: inputValue.deviceType,
      deviceModel: inputValue.deviceModel,
      location: inputValue.location,
      alias: inputValue.alias,
      serialNumber: inputValue.serialNumber,
      eurofinsCode: inputValue.eurofinsCode,
      sendToM3: inputValue.sendToM3,
      createTraceability: inputValue.createTraceability,
    };

    await put(`${backendBaseUrl}/api/devices/${deviceId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  return (
    <>
      <Breadcrumbs>
        <Breadcrumb to={`/`}>Välj Ort</Breadcrumb>
        <Breadcrumb to={`/location/${location}`}>{location}</Breadcrumb>
        <Breadcrumb to={`/location/${location}/${deviceId}`}>{deviceId} </Breadcrumb>
      </Breadcrumbs>
      <PageHead>
        <h1>Editera {device?.alias || device?.id}</h1>
      </PageHead>
      <LmBlock>
        {device && <DeviceCard device={device} onSave={handleDevice} />}
      </LmBlock>
      <LmBlock>
        {device && <DeviceEventsTable device={device} />}
      </LmBlock>
    </>
  );
};
