import {
  LmTabContainer,
  LmTabContent,
  LmTabContentContainer,
  LmTabNav,
} from './lm2/LmTabNav';

export const Tabs = () => {
  return (
    <LmTabNav>
      <LmTabContainer>
        <LmTabContentContainer>
          <LmTabContent to={''}>
            Orter
          </LmTabContent>
          <LmTabContent to={`/messages`}>
            Meddelande
          </LmTabContent>
        </LmTabContentContainer>
      </LmTabContainer>
    </LmTabNav>
  );
};
