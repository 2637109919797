import styled from '@emotion/styled';

export const StyledLinearLoader = styled.div`
  width: 100%;
  margin-top: -4px;
  div {
    height: 4px;
    background-color: #00963622;
    width: 100%;
    overflow: hidden;
    div {
      width: 100%;
      height: 100%;
      background-color: #009636;
      animation: indeterminateAnimation 1500ms infinite linear;
      transform-origin: 0% 50%;
    }
  }
  @keyframes indeterminateAnimation {
    0% {
      transform: translateX(0) scaleX(0);
    }
    40% {
      transform: translateX(0) scaleX(0.4);
    }
    100% {
      transform: translateX(100%) scaleX(0.5);
    }
  }
`;

export const LinearLoader = () => (
  <StyledLinearLoader>
    <div>
      <div></div>
    </div>
  </StyledLinearLoader>
);
