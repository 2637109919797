import React from "react";
import { MessageListItemProps } from "../models/Props";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { DeleteForever } from '@material-ui/icons';


function convertUnixTimeToString(unixTime:number) {
    const date = new Date(unixTime * 1000);
    const dateString = date.toLocaleString();
    return dateString;
  }

export const MessageListItem = ({
    message, deleteMessage }: MessageListItemProps) => {

      const labCode = ():string => {
        if (message.rules && message.rules?.length > 0) {
          return message.rules[0].value
        }
        return ""
      }
  
    return (
      <React.Fragment>
      <TableRow hover>
      <TableCell align="left">{message.text}</TableCell>
      <TableCell align="left">{message.type}</TableCell>
      <TableCell align="left">{message.location}</TableCell>
      <TableCell align="left">{labCode()}</TableCell>
      <TableCell align="left">{convertUnixTimeToString(message.startDate)}</TableCell>
      <TableCell align="left">{convertUnixTimeToString(message.endDate)}</TableCell>
      <TableCell align="left"><IconButton onClick={() => deleteMessage(message)} size='small' color={"error"}><DeleteForever/> 
      </IconButton></TableCell>
    </TableRow>
    </React.Fragment>
    );
  };
  