import styled from '@emotion/styled';

export const Card = styled.div`
  position: relative;
  border: 1px solid silver;
  margin-bottom: 25px;
`;

export const CardHeader = styled.div`
  background-color: #fafafa;
  padding: 20px;
  @media (max-width: 650px) {
    padding: 10px;
  }
`;

export const CardBody = styled.div`
  padding: 20px;
  @media (max-width: 650px) {
    padding: 10px;
  }
`;
export const CardColumn = styled.div<{ width?: number }>`
  min-width: ${({ width }) => (width ? `${width}%` : '100%')};

  @media (max-width: 650px) {
    min-width: 100%;
  }
`;

export const CardRow = styled.div<{ row?: boolean; right?: boolean }>`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const CardActions = styled.div<{justifyContent?: string}>`
  display: flex;
  justify-content: ${props => props.justifyContent || 'space-between'};
  padding: 20px;
  @media (max-width: 650px) {
    padding: 10px;
  }
`;
