import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { configuration } from './config';
import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
          <Auth0Provider
      domain={configuration.authDomain}
      clientId={configuration.authClientId}
      audience={configuration.authAudience}
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
    >
    <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
