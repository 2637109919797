import { useParams } from 'react-router';
import { Device, DeviceInput } from '../types';
import { LmBlock } from './lm2/LmBlock';
import { DeviceCard } from './DeviceCard';
import { Breadcrumb } from './BreadCrumb';
import { Breadcrumbs } from './BreadCrumbs';
import { PageHead } from './PageHead';
import { configuration } from "../config";
import useFetch from '../hooks/useFetch';

const { backendBaseUrl } = configuration;

type ParamsType = {
  deviceId: string;
  location: string;
};

export const AddDevice = () => {
  const { location } = useParams<ParamsType>();
  const { post } = useFetch();

  const handleDevice = async (inputValue: DeviceInput) => {
    const data: Device = {
      id: inputValue.deviceId,
      deviceId: inputValue.deviceId,
      deviceType: inputValue.deviceType,
      deviceModel: inputValue.deviceModel,
      location: location,
      alias: inputValue.alias,
      serialNumber: inputValue.serialNumber,
      eurofinsCode: inputValue.eurofinsCode,
      sendToM3: inputValue.sendToM3,
      createTraceability: inputValue.createTraceability,
      division: 'SPML',
    };

    await post(`${backendBaseUrl}/api/device`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  };

  return (
    <>
      <Breadcrumbs>
        <Breadcrumb to={`/`}>Välj Ort</Breadcrumb>
        <Breadcrumb to={`/location/${location}`}>{location}</Breadcrumb>
        <Breadcrumb to={`/location/${location}/add`}>
          Lägg till instrument
        </Breadcrumb>
      </Breadcrumbs>
      <PageHead>
        <h1>Lägg till instrument</h1>
      </PageHead>
      <LmBlock>
        <DeviceCard onSave={handleDevice} />
      </LmBlock>
    </>
  );
};
