import { useParams, Link } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { LmBlock } from './lm2/LmBlock';
import { LmBlockHeader } from './lm2/LmBlockHeader';
import { LmList } from './lm2/LmList';
import { LmListItem } from './lm2/LmListItem';
import { PageHead } from './PageHead';
import { LmButton } from './lm2/LmButton';
import { Breadcrumb } from './BreadCrumb';
import { Breadcrumbs } from './BreadCrumbs';
import { configuration } from "../config";
import useFetch from '../hooks/useFetch';

const { backendBaseUrl } = configuration;

type LocationType = {
  location: string;
};

export const Location = () => {
  let { location } = useParams<LocationType>();
  const [devices, setDevices] = useState([]);
  const { get } = useFetch();

  const getDevices = useCallback(async () => {
    const response = await get(`${backendBaseUrl}/api/devices?location=${location}`);
    setDevices(await response);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDevices, location]);

  useEffect(() => {
    getDevices();
  }, [getDevices]);

  return (
    <>
      <Breadcrumbs>
        <Breadcrumb to={`/`}>Välj Ort</Breadcrumb>
        <Breadcrumb to={`/location/${location}`}>{location}</Breadcrumb>
      </Breadcrumbs>
      <PageHead>
        <h1>Välkommen till {location}</h1>
        <Link to={`/location/${location}/add`}>
          <LmButton>Lägg till nytt instrument</LmButton>
        </Link>
      </PageHead>
      <LmBlock>
        <LmBlockHeader> Välj instrument att editera</LmBlockHeader>
        <LmList>
          {devices?.map((device: any) => {
            return (
              <LmListItem key={device.id} to={`/location/${location}/${device.id}`}>
                {device.alias || device.id}
              </LmListItem>
            );
          })}
        </LmList>
      </LmBlock>
    </>
  );
};
