import { Link } from 'react-router-dom';

export const LmListItem = ({ children, to }: any) => {
  return (
    <article key={children} className="lm__news-item">
      <Link to={to}>
        <h4 className="article-title right-icon-pos">
          {children}
          <i className="icon  icon--arrow-right" aria-hidden="true"></i>
        </h4>
      </Link>
    </article>
  );
};
