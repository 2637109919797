import { FC } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  to: string;
}

export const LmTabNav: FC = ({ children }) => {
  return <div className="tab-navigation  margin-top-20">{children}</div>;
};

export const LmTabContainer: FC = ({ children }) => {
  return <div className="tab-navigation__tab-container">{children}</div>;
};

export const LmTabContent: FC<Props> = ({ children, to }) => {
  return (
    <NavLink
      className={`tab-navigation__tab  js-tab `}
      exact
      activeClassName="is-active"
      to={to}
    >
      {children}
    </NavLink>
  );
};

export const LmTabContentContainer: FC = ({ children }) => {
  return (
    <div className="tab-navigation__tab-content-container">{children}</div>
  );
};
