interface Props {
  children: any;
}

export const LmBlock = ({ children }: Props) => {
  return (
    <div className="lm__block lm__block-box">
      <div className="lm__block-box">{children}</div>
    </div>
  );
};
