import styled from "@emotion/styled";

export const Card = styled.div`
  position: relative;
  border: 2px solid silver;
  padding: 20px;
  margin-bottom: 4px;
  @media (max-width: 650px) {
    padding: 10px;
  }
`;