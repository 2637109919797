import { useState, useEffect } from 'react';
import { LmBlock } from './lm2/LmBlock';
import { LmBlockHeader } from './lm2/LmBlockHeader';
import { LmList } from './lm2/LmList';
import { LmListItem } from './lm2/LmListItem';
import { PageHead } from './PageHead';
import { useAuth0 } from "@auth0/auth0-react";
import useFetch from '../hooks/useFetch';
import { configuration } from "../config";
import { LmButton } from './lm2/LmButton';
import { LinearLoader } from './shared/LinearLoader';
import { MessageModal } from './MessageModal/MessageModal';
import { Tabs } from './Tabs';

const { backendBaseUrl } = configuration;

export const MainPage = () => {
  const [items, setItems] = useState([]);
  const { get } = useFetch();

  const locationsArray: any[] = items.sort((a: any, b: any) =>
    a.name > b.name ? 1 : -1
  );

  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ messageModalOpen, setIsMessageModalOpen ] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      (async () => {
        const response = await get(`${backendBaseUrl}/api/GetLocations?onlyActive=true`);
        setItems(await response);
        setIsLoading(false)
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
    {isAuthenticated && messageModalOpen ? (
    <MessageModal onClose={() => setIsMessageModalOpen(false)}/>
    ) : null}
      <PageHead>
        <h1>Välj ort i listan</h1>
        <div>
        {isAuthenticated ? (
            <>
              <LmButton onClick={() => setIsMessageModalOpen(true)} style={{ marginRight: 20 }}>
                Lägg till meddelande
              </LmButton>
              <LmButton onClick={() => logout({ returnTo: window.location.origin })}>
                Logga ut
              </LmButton>
            </>
          ) : null}
        </div>
      </PageHead>
      <Tabs/>
      <LmBlock>
        <LmBlockHeader>Tillgängliga orter</LmBlockHeader>
        {isAuthenticated &&
          <LmList>
            {locationsArray?.map((location, index) => {
              return (
                <LmListItem key={index} to={`/location/${location.name}`}>
                  {location.name}
                </LmListItem>
              );
            })}
          </LmList>}
        {!isLoading && !isAuthenticated &&
          <LmButton style={{ marginTop: 20 }} onClick={() => loginWithRedirect()}>
             Login
          </LmButton>
        }
      </LmBlock>
      {isLoading ? <LinearLoader /> : null}
    </>
  );
};
