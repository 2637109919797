import styled from '@emotion/styled';
import { FcWithChildren } from '../interfaces/fcWithChildren';
import { Link } from 'react-router-dom';

interface Props {
  to: string;
}

const BreadcrumContainer = styled.div`
  :not(:first-child):before {
    content: ' → ';
  }
`;

const StyledBreadcrumb = styled(Link)`
  margin: 2px;
  :hover {
    text-decoration: underline;
  }
`;

export const Breadcrumb: FcWithChildren<Props> = ({ children, to }) => {
  return (
    <BreadcrumContainer>
      <StyledBreadcrumb to={to}>{children}</StyledBreadcrumb>
    </BreadcrumContainer>
  );
};
