import styled from '@emotion/styled';

const PageHeadRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

interface Props {
  children: any;
}

export const PageHead = ({ children }: Props) => {
  return <PageHeadRow>{children}</PageHeadRow>;
};
