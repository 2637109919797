import { useState, useEffect } from 'react';
import { LmBlock } from './lm2/LmBlock';
import { LmBlockHeader } from './lm2/LmBlockHeader';
import { PageHead } from './PageHead';
import { LmButton } from './lm2/LmButton';
import { configuration } from "../config";
import useFetch from '../hooks/useFetch';
import { useAuth0 } from '@auth0/auth0-react';
import { LinearLoader } from './shared/LinearLoader';
import { MessageList } from './MessagesList/MessageList';
import { Tabs } from './Tabs';
import { Message } from '../types';
import { MessageModal } from './MessageModal/MessageModal';

const { backendBaseUrl } = configuration;

export const Messages = () => {
  const [allMessages, setAllMessages] = useState([]);
  const { post, deleteRequest } = useFetch();
  const { isAuthenticated, logout } = useAuth0();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ messageModalOpen, setIsMessageModalOpen ] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      getAllMessages()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const getAllMessages = () => {
    setIsLoading(true);
    (async () => {
      const data = {
        all: true
      }
      const response = await post(`${backendBaseUrl}/api/messages`,
        {
          method: 'POST',
          body: JSON.stringify(data),
        });
      setAllMessages(await response.json());
      setIsLoading(false)
    })();
  }

  const deleteMessage = (message: Message) => {
    if (!window.confirm(
      `Är du säker?`
    )) {
      return;
    }
    (async () => {
      await deleteRequest(
        `${backendBaseUrl}/api/messages/${message.type}/${message.id}`,
        {
          method: 'DELETE'
        }
      );
      getAllMessages()
    })();
  }

  return (
    <>
      {isAuthenticated && messageModalOpen ? (
        <MessageModal onClose={() => setIsMessageModalOpen(false)} />
      ) : null}
      <PageHead>
        <h1>Alla meddelanden</h1>
        <div>
        {isAuthenticated ? (
            <>
              <LmButton onClick={() => setIsMessageModalOpen(true)} style={{ marginRight: 20 }}>
                Lägg till meddelande
              </LmButton>
              <LmButton onClick={() => logout({ returnTo: window.location.origin })}>
                Logga ut
              </LmButton>
            </>
          ) : null}
        </div>
      </PageHead>
      <Tabs/>
      <LmBlock>
        <LmBlockHeader>Alla meddelanden</LmBlockHeader>
        <MessageList deleteMessage={deleteMessage} messages={allMessages}/>
      </LmBlock>
      {isLoading ? <LinearLoader /> : null}
    </>
  );
};
